// Local Dev
// export const BASE_URL = `http://${window.location.hostname}:8000`;
// export const FILE_SERVER_URL = `http://${window.location.hostname}:8000/files`;

// SEI PMO
// export const BASE_URL = `https://sei-project-management-go-h27msqmlvq-et.a.run.app`;
// export const FILE_SERVER_URL = `https://sei-project-management-go-h27msqmlvq-et.a.run.app/files`;

// UNZA PMO
export const BASE_URL = `http://103.200.4.63:8383`;
export const FILE_SERVER_URL = `http://103.200.4.63:8383/files`;